import Loader from "Components/Loader/loader";
import { useFetchGet } from "Services/api";
import { formatDateFrenchLocale, getDateDifference } from "Services/functions";
import avatar3 from "assets/avatar3.webp";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FaPaperclip } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./CandidatureDetail.scss";

// Affiche le détail d'une candidature
const CandidatureDetail = (props) => {
  const [candidature, setCandidature] = useState(null);
  const candidatureData = useFetchGet(`/candidatures/${props.id}/detail`);

  useEffect(() => {
    candidatureData.loaded && setCandidature(candidatureData.data);
  }, [candidatureData.loaded]);

  return (
    <div className="cardDetail">
      {candidature ? (
        <>
          <div className="cardDetail__top">
            <div className="time-trash">
              <span
                className="h3_time"
                title={formatDateFrenchLocale(candidature.dateOfCandidature)}
              >
                {`Candidature reçue ${getDateDifference(
                  new Date(),
                  new Date(candidature.dateOfCandidature)
                )} `}
              </span>
            </div>
          </div>
          <div className="cardDetail__body">
            <img
              src={
                candidature.user?.imageUrl ||
                candidature?.profileImageUrl ||
                avatar3
              }
              alt="Image de profil"
              className="imgA"
              onError={(e) => {
                e.target.src = avatar3;
              }}
            />
            <div className="details">
              <h2 className="h2_name">
                {candidature.user.firstname + " " + candidature.user.surname}{" "}
              </h2>
              <span className="h3_poste">{candidature.user.profilTitle}</span>
              <span className="h3_poste">
                {props.message || candidature.message}
              </span>
            </div>
          </div>
          {!candidature.document && !candidature.extraResumeFilePath ? (
            <Link
              to={{
                pathname: `/profil-show/${candidature.user.id}`,
              }}
              state={{
                university: props.university,
              }}
              title={`Voir le profil de l'étudiant ${candidature.user.firstname} ${candidature.user.surname}`}
            >
              <div className="download_cv">
                {" "}
                <FaPaperclip className="fa" />
                Voir le cv
              </div>
            </Link>
          ) : (
            <a
              href={(() => {
                const url =
                  candidature.document || candidature.extraResumeFilePath;
                // Si l'une des urls contient http ou www, on la renvoie telle quelle
                if (url.includes("http") || url.includes("www")) {
                  return url;
                }
                // Sinon, on renvoie l'url complète
                return (
                  `${props.university.url}/media/${url}` ||
                  `/profil-show/${candidature.user.id}`
                );
              })()}
              target="_blank"
              rel="noopener noreferrer"
              className="download_cv"
            >
              {" "}
              <FaPaperclip className="fa" />
              Voir le cv
            </a>
          )}
        </>
      ) : (
        <Loader></Loader>
      )}
    </div>
  );
};

CandidatureDetail.propTypes = {
  message: PropTypes.string,
  id: PropTypes.number,
  university: PropTypes.object,
};

export default CandidatureDetail;
